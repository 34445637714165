import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { getHeaderDropdown } from '../selectors/dropdowns';

import { closePopup } from '../actions/popup';
import { updateProject } from '../actions/project';
import { createUploadFile, previewHeader } from '../actions/file';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Img from './Img';

class EditHeaderPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      header_id: this.props.header_id
    };

    this.handleChangeHeader = this.handleChangeHeader.bind(this);
  }

  handleChangeHeader(header_id) {
    this.setState({ header_id });
  }

  render() {
    const { onClosePopup, onChangeHeader, onUploadHeader, onPreview, loading, index, company_id, headers } = this.props;
    const { header_id } = this.state;

    return (
      <div id="replace-header-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Replace Header</h3>
          </div>
        </div>
        <div className="row popup-content">
          {headers.map(h =>
            <div className="small-12 medium-6 columns header-banner" key={h.file_id}>
              <Img src={getImageSrc(h, 'headers')}/>
              <div className="actions">
                <a className="button small" target='_blank' href={`/admin-preview-order.php?image_header=${getImageSrc(h, 'headers')}`}>Preview</a><a className="button small"><input type="radio" name="header_id" defaultChecked={header_id === h.file_id} onChange={e => {this.handleChangeHeader(h.file_id);}}/>&nbsp;&nbsp;Select</a>
              </div>
            </div>
          )}
          <div className="small-12 medium-6 columns header-banner">
            <div className="no-header"> Use blank header
            </div>
            <div className="actions">
              <a className="button small" target="_blank" href="/admin-preview-order.php">Preview</a><a className="button small"><input type="radio" defaultChecked={header_id == ''} onChange={e => {this.handleChangeHeader('');}}/>&nbsp;&nbsp;Select</a>
            </div>
          </div>
          <div className="small-12 medium-6 columns header-banner">
            <div className="new-header">
              <Dropzone onDrop={onUploadHeader(company_id)} style={{border: 'none'}} multiple={false} accept="image/*">
                <>
                  {loading ?
                    <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                  : 'Upload Header'}
                </>
              </Dropzone>
            </div>
          </div>
          <div className="small-12 columns">
            <a className="button" onClick={e => {e.preventDefault(); onChangeHeader(header_id); onClosePopup(); }}>Save &amp; Close</a>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    headers: getHeaderDropdown(state),
    company_id: state.identity.company_id,
    loading: state.display.loading.upload_file
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeHeader: header_id => {
      dispatch(updateProject(ownProps.job_id, 'header_id', ownProps.header_id, header_id));
    },
    onUploadHeader: company_id => files => {
      dispatch(createUploadFile(company_id, 'TENANT-HEADER', files[0]));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    },
  };
};

const ConnectedEditHeaderPopup = connect(mapStateToProps, mapDispatchToProps)(EditHeaderPopup);
export default ConnectedEditHeaderPopup;

